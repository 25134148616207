import "./App.css";
import Home from "./page/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactSub from "./components/ContactSub/ContactSub";
import RecruitSub from "./components/RecruitSub/RecruitSub";
import CoreValueSub from "./components/CoreValueSub/CoreValueSub";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* ホーム画面のルーティング設定 */}
          <Route path="/" element={<Home />} />
          {/* Subのパス用のルーティング設定 */}
          <Route path="/ContactSub" element={<ContactSub />} />
          <Route path="/RecruitSub" element={<RecruitSub />} />
          <Route path="/CoreValueSub" element={<CoreValueSub />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
