import "../../App.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const isBelow = useMediaQuery("(max-width: 1050px)");

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollToAnchor = (anchor) => {
    const anchorElement = document.getElementById(anchor);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
      setDrawerOpen(false); // リンクをクリックしたらDrawerを閉じる
    }
  };

  const navLinks = (
    <List>
      <ListItem onClick={() => scrollToAnchor("Top")}>
        <ListItemText primary="Top" />
      </ListItem>
      <ListItem onClick={() => scrollToAnchor("Mission")}>
        <ListItemText primary="Mission" />
      </ListItem>
      <ListItem onClick={() => scrollToAnchor("CoreValue")}>
        <ListItemText primary="CoreValue" />
      </ListItem>
      <ListItem onClick={() => scrollToAnchor("News")}>
        <ListItemText primary="News" />
      </ListItem>
      <ListItem onClick={() => scrollToAnchor("Contact")}>
        <ListItemText primary="Contact" />
      </ListItem>
      <ListItem onClick={() => scrollToAnchor("Recruit")}>
        <ListItemText primary="Recruit" />
      </ListItem>
      <ListItem onClick={() => scrollToAnchor("Member")}>
        <ListItemText primary="Member" />
      </ListItem>
      <ListItem onClick={() => scrollToAnchor("About")}>
        <ListItemText primary="About" />
      </ListItem>
    </List>
  );

  return (
    <>
      <header>
        <div className="header2">
          <div class="left-elements">
            {/* アイコン押したらTopに移動 */}
            <a href="#Top">
              <img
                className="HeaderLogo"
                // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
                src={process.env.PUBLIC_URL + "/img/logo.jpg"}
                alt="Rayns Architect LCC.ロゴ画像"
              />
            </a>
            <h3>Rayns Architect 合同会社</h3>
          </div>

          <div>
            <Box>
              {isBelow ? (
                <>
                  <MenuIcon
                    onClick={toggleDrawer}
                    style={{ fontSize: "32px", color: "black" }}
                  />
                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                  >
                    {navLinks}
                  </Drawer>
                </>
              ) : (
                <nav>
                  <ul>
                    <li>
                      <a href="#Top">Top</a>
                    </li>

                    <li>
                      <a href="#Mission">Mission</a>
                    </li>
                    <li>
                      <a href="#CoreValue">CoreValue</a>
                    </li>
                    <li>
                      <a href="#News">News</a>
                    </li>
                    <li>
                      <a href="#Contact">Contact</a>
                    </li>
                    <li>
                      <a href="#Recruit">Recruit</a>
                    </li>
                    <li>
                      <a href="#Member">Member</a>
                    </li>
                    <li>
                      <a href="#About">About</a>
                    </li>
                  </ul>
                </nav>
              )}
            </Box>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
