import { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

const Form = () => {
  // フォーム内容が送信されたかどうかを示すステート
  const [isComplete, setIsComplete] = useState(false);

  // フォーム内容の送信開始状態を示すステート
  const [isSubmitting, setIsSubmitting] = useState(false);

  //アラートの状態を示すステート（正常終了とエラーの2種類）
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  // // 初期表示時にfalseに設定（初めは送信が完了していない状態として始まる）
  useEffect(() => {
    setIsComplete(false);
  }, []);

  // 送信ボタン押下時、必須項目が全て入力されていない場合はエラーアラートを表示
  const handleClick = (e) => {
    if (!form.current.reportValidity()) {
      setOpenErrorSnackbar(true);
      return;
    }
    setIsSubmitting(true); // 送信処理中はボタンを無効化
    sendEmail(e); // 送信処理を実行
  };

  // フォーム内容が正常に送信された場合の
  // ユーザーがSnackbarの外側（clickaway）をクリックしたときに、Snackbarを閉じるためコード
  const handleCloseSuccessSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  // フォーム内容が正常に送信されなかった場合の
  // ユーザーがSnackbarの外側（clickaway）をクリックしたときに、Snackbarを閉じる
  const handleCloseErrorSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  // 問い合わせ内容をEmailJSを介して指定のメールアドレスに送信する
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // 環境変数からEmailJSの必要値を取得
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    // 上記の環境変数の値が1つでも誤りの場合はコンソールにエラーを出力
    if (!serviceID || !templateID || !publicKey) {
      console.error(
        "Service ID, Template ID, Public Key のどれかが誤っています"
      );
      return;
    }

    // 送信処理開始のフラグを立てることで、ユーザーが送信ボタンを2回目が押せなくなる
    setIsSubmitting(true);

    // EmailJSを使った送信処理
    emailjs
      .sendForm(serviceID, templateID, form.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          console.log("送信完了");
          setIsComplete(true); //フォーム内容が送信完了の状態
          setOpenSuccessSnackbar(true); //送信完了のメッセージ
        },
        (error) => {
          console.log("送信失敗", error.text);
          setIsComplete(false); //フォーム内容が送信未完了の状態
          setIsSubmitting(false); // 送信完了後、エラーで再入力するため送信中のフラグを解除
          setOpenErrorSnackbar(true); //送信エラーのメッセージ
        }
      );
  };

  // フォームが正常に送信された場合
  // 送信ボタンがHOMEへ戻るボタンに代わる際に利用するナビゲーション関数を取得
  const navigate = useNavigate();
  //HOMEへリダイレクトする
  const handleHomeRedirect = () => {
    navigate("/");
  };

  return {
    // 青：状態変数、黄色：関数
    isComplete,
    isSubmitting,
    openSuccessSnackbar,
    openErrorSnackbar,
    form,
    handleClick,
    sendEmail,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
    handleHomeRedirect,
  };
};

export default Form;
