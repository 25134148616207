import React from "react";
import "../../App.css";
import SubHeader from "../general/SubHeader";
import SubFooter from "../general/SubFooter";
import Scroll from "../general/Scroll";

const CoreValueSub = () => {
  return (
    <>
      <SubHeader />
      <Scroll />
      <div className="CoreValueSub">
        <div className="SubImg">
          <img
            className="CoreValueSubImg"
            src={process.env.PUBLIC_URL + "/img/CoreValue.png"}
            alt="CoreValueフォーム画像"
          />
        </div>

        <p className="CoreValue_title">9つのCore Values</p>
        <p className="CoreValue_subTitle sub">
          ～ あるべき姿と持ち続ける価値観 ～
        </p>

        <div className="CoreValue_content">
          <p className="CoreValue_p">
            ① <b>全力</b>の楽しいを大切にする
          </p>
          <p className="CoreValue_p">
            ② <b>真剣</b>に楽しいことを実行する
          </p>
          <p className="CoreValue_p">
            ③ 楽しくて<b>良い</b>ことを優先する
          </p>
          <p className="CoreValue_p">
            ④ 今最も<b>価値</b>があることを選択する
          </p>
          <p className="CoreValue_p">
            ⑤ <b>個性</b>の価値を最大化する
          </p>
          <p className="CoreValue_p">
            ⑥ 本当の困りごとを<b>ガチで</b>解決する
          </p>
          <p className="CoreValue_p">
            ⑦ <b>可能性</b>を自ら探求する
          </p>
          <p className="CoreValue_p">
            ⑧ <b>幸福度</b>の高い判断をする
          </p>
          <p className="CoreValue_p">
            ⑨ その先に<b>見える</b>ものに期待する
          </p>
        </div>
      </div>
      <SubFooter />
    </>
  );
};

export default CoreValueSub;
